import { pickupDate } from '@/utils/pickupDate';
import { useEffect, useState } from 'react';
import useLocalStorage from './useLocalStorage';
export interface pickupStateType {
  nextDay: number;
  nextDayTwo?: number;
  pickupCase: number;
}

export const usePickupTime = () => {
  const [localPickupTime, _] = useLocalStorage('pickupTime', {});
  const { currentDate, hour, minute, getPickupCase } = pickupDate();
  const beforeFourHourOrderDate = localPickupTime.earlyPickupTime.toString().slice(0,10) // 형식 YYYY/MM/DD
  const afterFourHourOrderDate = localPickupTime.latePickupTime.toString().slice(0,10) // 형식 YYYY/MM/DD
  const beforeFourHourOrderTime = localPickupTime.earlyPickupTime.toString().slice(-5) //형식 : h:mm 
  const afterFourHourOrderTime = localPickupTime.latePickupTime.toString().slice(-5) //형식 : h:mm 
  
  const isBeforeFourHour = hour < 16; //4시 이전
  const isBetweenTime = hour === 15 && minute >= 30; //3시반 ~ 4시

  const [pickupTime, setPickupTime] = useState(beforeFourHourOrderDate);
  const [pickupTimeTwo, setPickupTimeTwo] = useState(afterFourHourOrderDate);
  
  const [pickupHour, setPickupHour] = useState<string>(afterFourHourOrderTime);
  const pickupCase = getPickupCase();

  useEffect(() => {
    if (isBetweenTime) {
      setPickupTime(beforeFourHourOrderDate);
      setPickupTimeTwo(afterFourHourOrderDate);
    } else if (isBeforeFourHour) {
      setPickupTime(beforeFourHourOrderDate);
      setPickupHour(beforeFourHourOrderTime);
      setPickupTimeTwo('');
    } else {
      setPickupTime(afterFourHourOrderDate);
      setPickupHour(afterFourHourOrderTime);
      setPickupTimeTwo('');
    }
  }, [currentDate]);

  return { pickupTime, pickupTimeTwo, pickupCase, pickupHour, beforeFourHourOrderTime, afterFourHourOrderTime };
};
