import { FormattedMessage } from 'react-intl';
import './StoreModal.scss';
import Button from '@/components/Button';
import { deviceLocationType } from '@/types/data';
import { pickupHourData } from '@/utils/pickupHourData';

interface IProps {
  location: deviceLocationType;
  onClose: () => void;
  onClick: () => void;
}

export default function StoreModal({ location, onClose, onClick }: IProps) {
  const isHongdae = location === 'hongdae';

  return (
    <div className="store-modal-wrapper">
      <div className="store-modal-title">
        <FormattedMessage id={`view-store-location`} />
      </div>
      <div className="store-modal-main-wrapper">
        <div className="store-modal-main-left-wrapper">
          <p className="store-modal-sub-title">{`POCA SPOT ${isHongdae ? 'Hongdae' : 'Myeongdong'}`}</p>
          <p className="store-modal-section-title">
            [<FormattedMessage id="store_modal_address" />]
          </p>
          <ul className="store-modal-address-desc">
            <p>{`· ${
              isHongdae
                ? `48-14, Wausan-ro 29-gil, Mapo-gu, Seoul (1-minute walk from Exit 7 of Hongik Univ, Station)`
                : `33, Myeongdong 8na-gil, Jung-gu, Seoul(3-minute walk from Exit 5 of Myeongdong Station)`
            }`}</p>
            <p>{`· ${
              isHongdae ? 'Business Hours: 12:00 PM - 8:00 PM Everyday' : 'Business Hours: 10:00 AM - 10:00 PM Everyday'
            }`}</p>
          </ul>

          <p className="store-modal-section-title">
            [<FormattedMessage id="pick_up_schedule" />]
          </p>

          <div className="store-modal-pick-up-table-wrapper">
            <table border={1} className="pick-up-table">
              <thead>
                <tr className="pick-up-table_head">
                  <td className="pick-up-table_first" />
                  <td>
                    <FormattedMessage id="orders_before_4_hour" />
                  </td>
                  <td>
                    <FormattedMessage id="orders_after_4_hour" />
                  </td>
                </tr>
              </thead>
              {/* mon.- thu. */}
              <tr className="pick-up-table_contents">
                <td className="pick-up-table_first">
                  <FormattedMessage id="mon_to_thu" />
                </td>
                <td>
                  <FormattedMessage id="the_same_day_hour" values={{ pickupHour: pickupHourData[location].beforeFourHour }} />
                </td>
                <td>
                  <FormattedMessage id="tomorrow_hour" values={{ pickupHour: pickupHourData[location].afterFourHour }} />
                </td>
              </tr>
              {/* fri */}
              <tr className="pick-up-table_contents">
                <td className="pick-up-table_first">
                  <FormattedMessage id="fri" />
                </td>
                <td>
                  <FormattedMessage id="the_same_day_hour" values={{ pickupHour: pickupHourData[location].beforeFourHour }} />
                </td>
                <td>
                  <FormattedMessage id="next_monday_hour" values={{ pickupHour: pickupHourData[location].afterFourHour }} />
                </td>
              </tr>
              {/* sat */}
              <tr className="pick-up-table_contents">
                <td className="pick-up-table_first">
                  <FormattedMessage id="sat" />
                </td>
                <td>
                  <FormattedMessage id="next_monday_hour" values={{ pickupHour: pickupHourData[location].afterFourHour }} />
                </td>
                <td>
                  <FormattedMessage id="next_monday_hour" values={{ pickupHour: pickupHourData[location].afterFourHour }} />
                </td>
              </tr>
              {/* sun */}
              <tr className="pick-up-table_contents">
                <td className="pick-up-table_first">
                  <FormattedMessage id="sun" />
                </td>
                <td>
                  <FormattedMessage id="tomorrow_hour" values={{ pickupHour: pickupHourData[location].afterFourHour }} />
                </td>
                <td>
                  <FormattedMessage id="tomorrow_hour" values={{ pickupHour: pickupHourData[location].afterFourHour }} />
                </td>
              </tr>
            </table>
          </div>
          <p className="store-modal-notice">
            ※ <FormattedMessage id="store-modal-table-desc" />
          </p>
        </div>
        <img
          className="barcode-img"
          src={isHongdae ? '/images/hongdae-barcode.png' : '/images/myeongdong-barcode.png'}
          alt="hongdae-barcode-img"
          width={740}
          height={410}
        />
      </div>
      <div className="store-buttons-wrapper">
        <Button textColor="black" className="store-modal-cancel-button" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onClick}>Confirm</Button>
      </div>
    </div>
  );
}
