export const WebviewAction = () => {
  try {
    return (window as any).kiosk?.getDeviceId();
  } catch (e) {
    return null;
  }
};

export const WebviewActionShowKeyboard = () => {
  try {
    (window as any).kiosk?.showKeyboard();
  } catch (e) {
    console.log(e);
  }
};

export const WebviewActionHideKeyboard = () => {
  try {
    (window as any).kiosk?.hideKeyboard();
  } catch (e) {
    console.log(e);
  }
};
