import Button from '@/components/Button';
import { usePickupTime } from '@/hooks/usePickupTime';
import useReset from '@/hooks/useReset';
import '@/styles/order/success.scss';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Lottie from 'react-lottie-player';
import { useParams, useSearchParams } from 'react-router-dom';
import lottieJson from '@/assets/json/check.json';
import useToggle from '@/hooks/useToggle';
import BackModal from '@/components/BackModal';
import { pickupDate } from '@/utils/pickupDate';
import { pickupHourData } from '@/utils/pickupHourData';
import { deviceLocationType } from '@/types/data';

export function OrderSuccessPage() {
  const intl = useIntl();
  const params = useParams();
  const [time, setTime] = useState(20);
  const { goHome } = useReset();
  const { pickupTime } = usePickupTime();
  const backModal = useToggle({ showing: false });
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const pickupLocation = searchParams.get('pickupLocation') as deviceLocationType
  const { hour } = pickupDate();

  useEffect(() => {
    const id = setInterval(() => {
      setTime((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    if (time === 0) {
      goHome();
    }
  }, [time]);

  return (
    <div className="order-success-page-wrapper">
      <div className="order-success-container">
        <div className="order-success-page-title-section">
          <Lottie animationData={lottieJson} loop={false} play speed={0.5} style={{ width: 130, height: 130 }} />
          <p className="order-success-page-title">
            <FormattedMessage id="order_success_title" />
          </p>
          <div className="order-success-page-desc">
            <p>
              <FormattedMessage id="order_success_desc1" />
            </p>
            <p>
              <FormattedMessage id="order_success_desc2" />
            </p>
          </div>
          {/* pickupHourData[deviceLocation].beforeFourHour */}
          <div className="order-success-page-pickup-schedule-section">
            <FormattedMessage id="available_pickup_schedule" />
            <span className="order-success-time-text">{`${pickupTime} ${hour >= 16 ? pickupHourData[pickupLocation].afterFourHour :  pickupHourData[pickupLocation].beforeFourHour } PM`}</span>
          </div>
        </div>
        <div className="order-success-img-box">
          <div className="order-success-number-box">
            <p className="order-success-text-box">
              <span>
                <FormattedMessage id="order_cart_id" />
              </span>
              <span className="order-success-bold-text">{params.id}</span>
            </p>
            <div className="order-success-email-section">
              <p className="order-success-text-box">
                <span>
                  <FormattedMessage id="order_section1_input4_label" />
                </span>
                <span>{email}</span>
              </p>
            </div>
          </div>
        </div>
        <span className="order-success-page-email-desc">
          <FormattedMessage id="order_success_email_desc" />
        </span>
      </div>

      <span className="order-success-explanation">
        {intl.formatMessage({ id: 'order_success_order-info3' })} <span className="order-success-explanation-bold">{time} </span>
        <FormattedMessage id="order_success_order-info4" />
      </span>

      <div className="order-success-page-buttons">
        <Button className="stay-on-screen" textColor="black" onClick={() => setTime(20)}>
          <FormattedMessage id="order_success_stay_screen" />
        </Button>
        <Button onClick={() => backModal.open()}>
          <FormattedMessage id="order_button_main" />
        </Button>
      </div>
      {backModal.isShowing && <BackModal onClick={goHome} onClose={() => backModal.close()} />}
    </div>
  );
}
