import { useKioskDeviceLocation } from '@/hooks/useKioskDeviceLocation';
import { daysArr } from '@/utils/daysArr';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Checkbox from '../Checkbox';
import { useFormContext, useWatch } from 'react-hook-form';
import { OrderSendForm } from '@/types/order';
import StoreModal from './StoreModal/StoreModal';
import useToggle from '@/hooks/useToggle';
import { pickupHourData } from '@/utils/pickupHourData';
import { deviceLocationType } from '@/types/data';

// 픽업 문구 케이스
// 1. Today 7:00 PM ~ following 3 days
// 2. Tomorrow 12:00 PM ~ following 3 days
// 3. Next Monday 12:00 PM~ following 3 days

export const PickUpNotice = () => {
  const daysNumber = moment().day();
  const days = daysArr[daysNumber];
  const [pickUpTextCase, setPickTextCase] = useState<{ before: number; after: number }>({ before: 1, after: 2 });
  const [deviceLocation] = useKioskDeviceLocation();
  const storeModal = useToggle({ showing: false });
  const pickupLocation: deviceLocationType = useWatch({ name: 'pickup_location' }) ?? deviceLocation;
  const { setValue } = useFormContext<OrderSendForm>();

  useEffect(() => {
    if (days !== '토' && days !== '일') {
      setPickTextCase({ before: 1, after: days === '금' ? 3 : 2 });
    } else {
      setPickTextCase({ before: 3, after: 3 });
    }
    setValue('pickup_location', deviceLocation);
  }, []);

  return (
    <div className="order-page-section">
      <div>
        <div className="order-page-title-section">
          <p className="order-page-section-agreement-title">
            <FormattedMessage id="pick_up_notice_title" />
          </p>
        </div>
        <p className="order-page-section-agreement-title-desc">
          <FormattedMessage id="pick_up_location_desc" />
        </p>
      </div>
      <div className="select-pick-up-location">
        <p className="select-pick-up-location-title">
          <FormattedMessage id="select_pick_up_location" />
        </p>
        <form className="select-device-location">
          {deviceLocation === 'hongdae' ? (
            <>
              <div className="select-device-current-location">
                <Checkbox
                  checked={pickupLocation === 'hongdae'}
                  label={'Hongdae'}
                  onClick={() => setValue('pickup_location', 'hongdae')}
                />
                <span className="select-device-current-location-text">(Current store)</span>
              </div>
              <Checkbox checked={pickupLocation === 'myeongdong'} label={'Myeongdong'} onClick={() => storeModal.open()} />
            </>
          ) : (
            <>
              <div className="select-device-current-location">
                <Checkbox
                  checked={pickupLocation === 'myeongdong'}
                  label={'Myeongdong'}
                  onClick={() => setValue('pickup_location', 'myeongdong')}
                />
                <span className="select-device-current-location-text">(Current store)</span>
              </div>
              <Checkbox checked={pickupLocation === 'hongdae'} label={'Hongdae'} onClick={() => storeModal.open()} />
            </>
          )}
        </form>
      </div>
      <div>
        <div className="pick-up-notice-sub-title">
          <FormattedMessage id="pick_up_notice_sub_title_1" />
        </div>
        <ul className="pick-up-notice-ul">
          <li>
            <FormattedMessage id="pick_up_notice_sub_title_1_list_1" />
          </li>
          <li>
            <FormattedMessage id="pick_up_notice_sub_title_1_list_2" />
          </li>
        </ul>
      </div>
      <div>
        <div className="pick-up-notice-sub-title">
          <FormattedMessage id="pick_up_notice_sub_title_2" />
        </div>
        <div className="pick-up-notice-sub-title-desc">
          <FormattedMessage
            id="pick_up_notice_sub_desc_1"
            values={{
              openHour: pickupLocation === 'hongdae' ? '12:00' : '10:00',
              closeHour: pickupLocation === 'hongdae' ? '8:00' : '10:00'
            }}
          />
        </div>

        <div className="pick-up-table-wrapper">
          <table border={1} className="pick-up-table">
            <thead>
              <tr className="pick-up-table_head">
                <td className="pick-up-table_first" />
                <td>
                  <FormattedMessage id="orders_before_4_hour" />
                </td>
                <td>
                  <FormattedMessage id="orders_after_4_hour" />
                </td>
              </tr>
            </thead>
            <tr className="pick-up-table_contents">
              <td className="pick-up-table_first">
                <FormattedMessage id={`pick_up_table_days_${daysNumber}`} />
              </td>
              <td>
                <FormattedMessage
                  id={`pick_up_table_case_text_${pickUpTextCase?.before}`}
                  values={{
                    pickupFourAfterHour: pickupHourData[pickupLocation].afterFourHour,
                    pickupFourBeforeHour: pickupHourData[pickupLocation].beforeFourHour
                  }}
                />
              </td>
              <td>
                <FormattedMessage
                  id={`pick_up_table_case_text_${pickUpTextCase?.after}`}
                  values={{
                    pickupFourAfterHour: pickupHourData[pickupLocation].afterFourHour,
                    pickupFourBeforeHour: pickupHourData[pickupLocation].beforeFourHour
                  }}
                />
              </td>
            </tr>
          </table>
        </div>
      </div>
      {storeModal.isShowing && (
        <StoreModal
          location={pickupLocation === 'hongdae' ? 'myeongdong' : 'hongdae'}
          onClose={() => storeModal.close()}
          onClick={() => {
            setValue('pickup_location', pickupLocation === 'hongdae' ? 'myeongdong' : 'hongdae');
            storeModal.close();
          }}
        />
      )}
    </div>
  );
};
