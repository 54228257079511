import useLocalStorage from '@/hooks/useLocalStorage';
import { WebviewAction } from '@/utils/action';

export const useKioskDeviceLocation = () => {
  const deviceId: string = WebviewAction() ?? 'kioskdev';
  const deviceLocationCheck: 'hongdae' | 'myeongdong' = deviceId.slice(5, 6) === '0' ? 'hongdae' : 'myeongdong';
  const [deviceLocation, setDeviceLocation] = useLocalStorage('pickupLocation', deviceLocationCheck);

  return [deviceLocation, setDeviceLocation];
};
