import { FormattedMessage, useIntl } from 'react-intl';
import Input, { InputWithMenu } from '../Input';
import { useFormContext, useWatch } from 'react-hook-form';
import { OrderSendForm } from '@/types/order';
import { useEffect, useState } from 'react';
import useLocale from '@/hooks/useLocale';
import { countriesEnData } from '@/utils/countriesEnData';
import { countriesChData } from '@/utils/countriesChData';
import { countriesJaData } from '@/utils/countriesJaData';
import { countriesEsData } from '@/utils/countriesEsData';

const emailDomainData = ['gmail.com', 'hotmail.com', 'yahoo.com', 'icloud.com', 'Enter manually'];

const emailDomainRegexp = /^([\w-]+\.)+[\w-]{2,4}$/;

export const CustomerInfo = () => {
  const intl = useIntl();
  const { register, setValue, resetField } = useFormContext<OrderSendForm>();
  const [isOptionVisible, setIsOptionVisible] = useState(false);
  const [emailDomainErrorMessage, setEmailDomainErrorMessage] = useState<string | undefined>();
  const countryValue = useWatch({ name: 'customer_country_code' });
  const domainValue = useWatch({ name: 'customer_email_2' });
  const { locale } = useLocale();
  const [countriesDataArr, setCountriesDataArr] = useState(countriesEnData);

  useEffect(() => {
    switch (locale) {
      case 'en':
        return setCountriesDataArr(countriesEnData);
      case 'ch':
        return setCountriesDataArr(countriesChData);
      case 'ja':
        return setCountriesDataArr(countriesJaData);
      case 'es':
        return setCountriesDataArr(countriesEsData);
    }
  }, [locale]);

  useEffect(() => {
    setEmailDomainErrorMessage(
      emailDomainRegexp.test(domainValue) || domainValue === undefined ? '' : 'Please enter a correct email address.'
    );
  }, [domainValue]);

  useEffect(() => {
    setValue('customer_email_2', 'gmail.com');
  }, []);

  const handleEmailDomainClick = (emailDomain: string) => {
    if (emailDomain === 'Enter manually') {
      resetField('customer_email_2');
    } else {
      setValue('customer_email_2', emailDomain);
    }
  };

  return (
    <div className="order-page-section order-page-top-margin">
      <div className="order-page-section-title-area">
        <span className="order-page-section-title-text">
          <FormattedMessage id="order_section1_title" />
        </span>
      </div>
      <div className="order-page-section-row">
        {/* First name */}
        <Input
          label={<span className="input-label-text">{intl.formatMessage({ id: 'order_section1_input1_label' })}</span>}
          containerClassName="order-page-section-item-full"
          placeholder={intl.formatMessage({ id: 'order_section1_input1_placeholder' })}
          register={register('customer_first_name', { required: true })}
        />
        {/* Last name */}
        <Input
          label={<span className="input-label-text">{intl.formatMessage({ id: 'order_section1_input2_label' })}</span>}
          containerClassName="order-page-section-item-full"
          placeholder={intl.formatMessage({ id: 'order_section1_input2_placeholder' })}
          register={register('customer_last_name', { required: true })}
        />
      </div>
      {/* Phone number */}
      <InputWithMenu
        label={<span className="input-label-text">{intl.formatMessage({ id: 'order_section1_input3_label' })}</span>} //Phone number
        menus={countriesDataArr}
        inputContainerClassName="order-page-section-item-single"
        placeholder={intl.formatMessage({ id: 'order_section1_input3_input-placeholder' })}
        register={register('customer_phone_number', { required: true })}
        menuValue={countryValue}
        onMenuSelect={(code, codeNumber) => {
          setValue('customer_country_code', code);
          setValue('customer_country_code_number', codeNumber);
        }}
        type={'number'}
      />
      {/* E-mail */}
      <div className="email-input">
        <Input
          label={
            <div className="email-input-label-wrapper">
              <span className="input-label-text">
                {<span className="input-label-text">{intl.formatMessage({ id: 'order_section1_input4_label' })}</span>}
              </span>
              <div className="email-input-label">(<FormattedMessage id='email_input_desc'/>)</div>
            </div>
          }
          containerClassName={`${intl.locale === 'ja' && 'input-ja'} email-local-parts`}
          placeholder={'E-mail'}
          register={register('customer_email_1', { required: true })}
        />
        <div className="email-whelk-symbol">@</div>
        <Input
          containerClassName={`${intl.locale === 'ja' && 'input-ja'} email-domain`}
          errorMessage={emailDomainErrorMessage}
          register={register('customer_email_2')}
        />
        <div className="input-menu-wrap-admin domain-select" onClick={() => setIsOptionVisible((pev) => !pev)}>
          <div className="input-menu">
            <span className="option-value">
              {emailDomainData.includes(domainValue) ? domainValue : <FormattedMessage id="email_domain_enter_manually" />}
            </span>
            {isOptionVisible && (
              <ul className="input-menu-domain">
                {emailDomainData.map((emailDomain) => (
                  <li key={emailDomain} className="input-menu-option" onClick={() => handleEmailDomainClick(emailDomain)}>
                    {emailDomain === 'Enter manually' ? <FormattedMessage id="email_domain_enter_manually" /> : emailDomain}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <img
            src="/icons/arrow-down.svg"
            alt="arrow icon"
            className="input-menu-arrow-down"
            style={{ transform: isOptionVisible ? 'rotate(180deg)' : 'rotate(0)' }}
          />
        </div>
      </div>
    </div>
  );
};
