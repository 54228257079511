import { deviceLocationType } from "@/types/data";

type pickupHourArrType = {
  [key in deviceLocationType]: { beforeFourHour: string; afterFourHour: string };
};

export const pickupHourData: pickupHourArrType = {
  hongdae: {
    beforeFourHour: '7:00',
    afterFourHour: '12:00'
  },
  myeongdong: {
    beforeFourHour: '8:00',
    afterFourHour: '2:00'
  }
};
