import { FormattedMessage } from 'react-intl';
import Button from '../Button';
import { useCart } from '@/hooks';
import { getPrice } from '@/utils/getPrice';
import useLocalStorage from '@/hooks/useLocalStorage';
import { useFormContext, useWatch } from 'react-hook-form';
import { OrderSendForm } from '@/types/order';
import useToggle from '@/hooks/useToggle';
import BackModal from '../BackModal';
import useApi from '@/hooks/useApi';
import { postPurchase } from '@/apis/checkout';
import OrderErrorModal from '../OrderErrorModal';
import CheckModal from '../CheckModal';
import { useState } from 'react';
import { useWebViewRouter } from '@/hooks/useWebViewRouter';
import { ICheckRequest } from '@/types/checkout';
import CartItem from './CartItem';
import CartHeader from './CartHeader';
import CartResultSection from './CartResultSection';
import { WebviewAction } from '@/utils/action';
import { useKioskDeviceLocation } from '@/hooks/useKioskDeviceLocation';
import { deviceLocationType } from '@/types/data';
import { useMutation } from '@tanstack/react-query';

export const Cart = () => {
  const { direct } = useWebViewRouter();
  const { cartData } = useCart();
  const { fetchApi } = useApi();
  const [currency] = useLocalStorage('currency', null);
  const { handleSubmit, formState } = useFormContext<OrderSendForm>();
  const [isSubmit, setIsSubmit] = useState(false);
  const [errorMessage, setErrormessage] = useState('');
  const [deviceLocation] = useKioskDeviceLocation();

  const subtotal = cartData?.reduce((prev, cur) => (prev += Number(cur.offer.price) * 1), 0).toFixed() || '0';
  const shippingFee = 0;

  const { isDirty, isValid } = formState;
  const countryCode = useWatch({ name: 'customer_country_code' });
  const agreement1 = useWatch({ name: 'service_agreement_1' });
  const agreement2 = useWatch({ name: 'service_agreement_2' });
  const disabled = !(isDirty && isValid) || !agreement1 || !agreement2 || !countryCode;
  const pickupLocation: deviceLocationType = useWatch({ name: 'pickup_location' }) ?? deviceLocation;
  const kioskId: string = WebviewAction() ?? 'kioskdev';

  // toggle
  const orderErrorModal = useToggle({ showing: false });
  const backModal = useToggle({ showing: false });
  const checkModal = useToggle({ showing: false });

  const { mutate, isIdle } = useMutation({
    mutationFn: async (data: OrderSendForm) => {
      const cart_offers = cartData?.map((i) => i.offer.id) || [];
      const email = data.customer_email_1 + '@' + data.customer_email_2;
      const params: ICheckRequest = {
        offer_list: cart_offers,
        pick_up_location: pickupLocation,
        kiosk_device: kioskId,
        payment: {
          offer_total: subtotal,
          shipping_fee: shippingFee
        },
        customer: {
          first_name: data.customer_first_name,
          last_name: data.customer_last_name,
          country_code: data.customer_country_code,
          phone_number: data.customer_phone_number,
          email
        }
      };

      const realpurchase = fetchApi((token) => postPurchase(token, params));
      return realpurchase;
    },
    onSuccess: (data: any) => {
      direct(`/order/success/${data.custom_order_id}?email=${data.customer_email}&pickupLocation=${pickupLocation}`);
    },
    onError: (e: any) => {
      setIsSubmit(false);
      setErrormessage(e.message ?? e.code);
      checkModal.close();
      orderErrorModal.open();
    }
  });

  const onSubmit = (data: OrderSendForm) => {
    if (isIdle) mutate(data);
  };

  return (
    <div className="order-page-cart">
      <CartHeader />

      <div className="order-page-cart-items-area">{cartData?.map((item) => <CartItem cartItemData={item} />)}</div>

      <CartResultSection cartDataLength={cartData?.length ?? 0} subtotal={Number(subtotal)} />

      <div className="order-page-cart-button-area">
        <Button className="order-page-cart-back-button" textColor="#000000" onClick={backModal.open}>
          <FormattedMessage id="order_cart_back" />
        </Button>
        <Button className="order-page-cart-next-button" disabled={disabled} onClick={checkModal.open}>
          <FormattedMessage id="order_cart_next" />
        </Button>
      </div>

      {checkModal.isShowing && (
        <CheckModal
          onClose={checkModal.close}
          onContinueClick={handleSubmit(onSubmit)}
          total={`${getPrice(Number(subtotal).toString(), currency)}`}
          isSubmit={isSubmit}
        />
      )}

      {backModal.isShowing && <BackModal onClose={backModal.close} onClick={() => direct('/photocard')} />}

      {orderErrorModal.isShowing && (
        <OrderErrorModal
          onAgainClick={() => {
            orderErrorModal.close();
            setErrormessage('');
          }}
          errorMessage={errorMessage}
        />
      )}
    </div>
  );
};
