import { useEffect } from 'react';
import './styles/index.scss';
import { IntlProvider } from 'react-intl';
import enMsg from '@/lang/english.json';
import chMsg from '@/lang/chinese.json';
import esMsg from '@/lang/spain.json';
import jaMsg from '@/lang/japanese.json';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { HomePage } from '@/routes/index';
import useLocale from './hooks/useLocale';
import { type LocaleValue } from './constants/locale';
import { OnboardingPage } from './routes/onboarding';
import PhotocardListPage from './routes/photocard';
import OrderPage from './routes/order';
import { QueryClient, QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import useAuth from './hooks/useAuth';
import { issueAccessToken } from './apis/auth';
import useApi from './hooks/useApi';
import { getArtistGroups, getArtistMembers, getPhotocards, getPickupTime } from './apis/main';
import { OrderSuccessPage } from './routes/order/success/[id]';
import { WebviewAction } from './utils/action';
import { inject } from '@vercel/analytics';
import useLocalStorage from './hooks/useLocalStorage';
import { validParams } from './utils/valid-params';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import PromotionPage from './routes/promotion';
import GoogleTagManager from './components/GoogleTagManager/GoogleTagManager';
import moment from 'moment';

const messages = { en: enMsg, ch: chMsg, es: esMsg, ja: jaMsg } satisfies Record<LocaleValue, any>;

const queryClient = new QueryClient();

const InnerApp = () => {
  const { isLogined } = useAuth();
  const queryClientFromHook = useQueryClient();
  const { fetchApi } = useApi();

  useEffect(() => {
    const prefetchPhotocards = async () => {
      const groupsResponse = await queryClientFromHook.fetchQuery({
        queryFn: () => getArtistGroups(),
        queryKey: ['group-list']
      });
      if (groupsResponse.groups && groupsResponse.groups.length > 0) {
        const selectedArtistId = groupsResponse.groups[0].id;
        const membersResponse = await queryClientFromHook.fetchQuery({
          queryFn: () => getArtistMembers(selectedArtistId ?? 0),
          queryKey: ['member-list', selectedArtistId]
        });
        if (membersResponse.members && membersResponse.members.length > 0) {
          await queryClientFromHook.prefetchInfiniteQuery({
            queryFn: ({ pageParam = undefined }) =>
              fetchApi((accessToken) =>
                getPhotocards({
                  params: validParams({ sort: 'new', group: '5', member: '0', page: pageParam }),
                  token: accessToken
                })
              ),
            queryKey: ['card-list', String(selectedArtistId), '0', null, 'new', ''],
            getNextPageParam: (lastPage) => {
              return lastPage.next_page;
            }
          });
        }
      }
    };

    if (isLogined) {
      prefetchPhotocards();
    }
  }, [isLogined]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/onboarding" element={<OnboardingPage />} />
      <Route path="/promotion" element={<PromotionPage />} />
      <Route path="/photocard/*" element={<PhotocardListPage />} />
      <Route path="/order" element={<OrderPage />} />
      <Route path="/order/success/:id" element={<OrderSuccessPage />} />
    </Routes>
  );
};

function App() {
  const { locale } = useLocale();
  const { updateToken } = useAuth();
  const { fetchApi } = useApi();
  const [searchParams] = useSearchParams();
  const [storedValue, setValue] = useLocalStorage('currency', { currency: '₩', value: 1000, text: '₩ - KRW - South Korea Won' });
  const [_, setPickupTime] = useLocalStorage('pickupTime', {});
  const [_deviceLocation, setDeviceLocation] = useLocalStorage('pickupLocation', 'hongdae');

  inject();

  useEffect(() => {
    setValue(storedValue);

    const init = async () => {
      const accessToken = searchParams.get('token');
      if (accessToken) {
        updateToken({ access: accessToken, refresh: '' });
      } else {
        const deviceId = WebviewAction() ?? 'kioskdev';
        const response = await issueAccessToken({ params: { device_id: deviceId } });
        updateToken({ access: response.access, refresh: response.refresh });
      }

      const pickupTimeResponse = await queryClient.fetchQuery({
        queryFn: () => fetchApi((accessToken) => getPickupTime(accessToken))
      });

      const earlyPickupTime = moment(pickupTimeResponse.early_pickup_time).format('YYYY/MM/DD h:mm');
      const latePickupTime = moment(pickupTimeResponse.late_pickup_time).format('YYYY/MM/DD h:mm');
      setPickupTime({ earlyPickupTime, latePickupTime });
      setDeviceLocation(pickupTimeResponse.offline_store);
    };
    init();
  }, []);

  const GTM_ID = process.env.REACT_APP_PUBLIC_GTM_ID;

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <IntlProvider locale={locale} messages={messages[locale]}>
          <InnerApp />
          {GTM_ID && <GoogleTagManager gtmId={GTM_ID} />}
          <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-right" />
        </IntlProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
