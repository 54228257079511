import { addToCart, getArtistMembers, getPhotocards } from '@/apis/main';
import useApi from '@/hooks/useApi';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PhotocardItem from '../PhotocardItem';
import { Photocard } from '@/types/photocard';
import useToggle from '@/hooks/useToggle';
import { Loading } from '../Loading';
import { NothingCard } from '../NothingCard/NothingCard';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import PhotocardModal from '@/routes/photocard/[id]';
import useLocalStorage from '@/hooks/useLocalStorage';
import { useCart } from '@/hooks';
import { useSearchParams } from 'react-router-dom';
import { validParams } from '@/utils/valid-params';
import useI18nText from '@/hooks/useI18nText';
import { FormattedMessage } from 'react-intl';

export function CardsList({ onClick }: { onClick: () => void }) {
  const { fetchApi } = useApi();
  const [searchParams] = useSearchParams();
  const [currency] = useLocalStorage('currency', null);
  const contentRef = useRef<HTMLDivElement>(null);
  const lastElement = useRef<HTMLDivElement>(null);
  const outOfStockModal = useToggle({ showing: false });
  const observerModal = useToggle({ showing: false });
  const { observe, unobserve } = useIntersectionObserver(() => observerModal.open());
  const { cartData } = useCart();
  const [selected, setSelected] = useState<Photocard | null>(null);
  const queryClient = useQueryClient();
  const successAddCartModal = useToggle({ showing: false });

  const category = searchParams.get('category');
  const sort = searchParams.get('sort') ?? 'new';
  const group = searchParams.get('group') ?? '5';
  const member = searchParams.get('member') ?? '0';
  const q = searchParams.get('q') ?? '';

  const errorModalText1 = useI18nText('error_modal_desc_1');
  const errorModalText2 = useI18nText('error_modal_desc_2');

  const {
    data: cardsData,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  } = useInfiniteQuery({
    queryKey: ['card-list', group, member, category, sort, q],
    queryFn: ({ pageParam = undefined }) =>
      fetchApi((accessToken) =>
        getPhotocards({ params: validParams({ category, sort, group, member, q, page: pageParam }), token: accessToken })
      ),
    getNextPageParam: (lastPage) => lastPage.next_page
  });

  const { data: artistMembers } = useQuery({
    queryFn: () => getArtistMembers(Number(group)),
    queryKey: ['member-list', Number(group)],
    select: (data) => data.members
  });

  const addCartMutation = useMutation({
    mutationFn: (offerId: number) => fetchApi((accessToken) => addToCart(offerId, accessToken)),
    mutationKey: ['cart'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['card-list', group, member, category, sort, q] });
      queryClient.invalidateQueries({ queryKey: ['cart'] });
      setSelected(null);

      successAddCartModal.open();
      setTimeout(() => {
        successAddCartModal.close();
      }, 600);
    },

    onError: (data: any) => {
      alert(`${errorModalText1}\n${errorModalText2}`);
    }
  });

  const moveTop = useCallback(() => {
    contentRef?.current?.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [contentRef]);

  const cards = useMemo(() => {
    const results = cardsData ? cardsData.pages.flatMap(({ results }) => results) : [];
    results && results.length < 1 ? outOfStockModal.open() : outOfStockModal.close();
    if (isLoading) moveTop();
    return results;
  }, [cardsData, isLoading]);

  const isSoloArtist = useMemo(() => artistMembers?.length === 1, [artistMembers?.length]);

  useEffect(() => {
    const getNext = async () => {
      if (observerModal.isShowing && !isFetchingNextPage && lastElement.current) {
        unobserve(lastElement.current);
        if (hasNextPage) {
          await fetchNextPage();
        }
        observerModal.close();
        observe(lastElement.current);
      }
    };
    getNext();
  }, [observerModal]);

  useEffect(() => {
    if (lastElement.current) {
      observe(lastElement.current);
    }
    return () => {
      if (lastElement.current) {
        unobserve(lastElement.current);
      }
    };
  }, []);

  return (
    <>
      <div className="photocard-list-area" ref={contentRef} onClick={onClick}>
        {cards?.map((photocard) => (
          <PhotocardItem
            key={photocard.id}
            photocard={photocard}
            onClick={() => !isLoading && setSelected(photocard)}
            isSoloArtist={isSoloArtist}
          />
        ))}
        <div ref={lastElement} />

        {isLoading && <Loading className="full-loading" />}
        {!isLoading && isFetchingNextPage && !outOfStockModal.isShowing && <Loading className="bottom-loading" />}
        {!isLoading && !isFetchingNextPage && outOfStockModal.isShowing && <NothingCard />}
      </div>

      {selected && (
        <PhotocardModal
          {...selected}
          onAddToCart={(offerId) => {
            if (!cartData || cartData.length < 40) addCartMutation.mutate(offerId);
          }}
          onClose={() => setSelected(null)}
          currency={currency}
        />
      )}
      {successAddCartModal.isShowing && (
        <div className="empty-cart-modal-wrapper">
          <FormattedMessage id="added_to_cart" />
        </div>
      )}
    </>
  );
}
